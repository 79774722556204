import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "../components/SubpageHeader";
import "styles/pages/page-solutions.scss";
import TitleWithLine from "../components/TitleWithLine";

const CustomSolutions = ({ pageContext }) => {
  const cs = pageContext.pageContent;

  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        title={pageContext.title}
        image={require("assets/images/example-subpage-bg.jpg").default}
      />
      <section className="section-systems">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-systems__inner">
                <TitleWithLine variant="bottom">
                  {cs.systemsImplementationTitle}
                </TitleWithLine>
                <div
                  className="section-systems__desc"
                  dangerouslySetInnerHTML={{
                    __html: cs.systemsImplementationText1,
                  }}
                />
                <div className="section-systems__software">
                  {cs.systemsImplementationColorTiles.map((item, index) => {
                    return (
                      <div
                        className="section-systems__software section-systems__software--flex"
                        key={index}
                      >
                        <div className="section-systems__rectangle"></div>
                        <p>{item.tileSoftware}</p>
                      </div>
                    );
                  })}
                  <div
                    className="section-systems__subdesc"
                    dangerouslySetInnerHTML={{
                      __html: cs.systemsImplementationText2,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src={require("assets/images/custom_img_1.png").default}
                alt=""
                className="img-fluid section-systems__img"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-reviews">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img
                src={cs.dueDiligenceImage?.sourceUrl}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6">
              <div className="section-reviews__inner">
                <TitleWithLine variant="bottom">
                  {cs.dueDiligenceTitle}
                </TitleWithLine>
                <div
                  className="section-reviews__desc"
                  dangerouslySetInnerHTML={{ __html: cs.dueDiligenceText }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default CustomSolutions;
